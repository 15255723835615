.app-menu-left {
  width: 256px;
  height: 100vh;
  background: var(--gray-800);
  display: flex;
  flex-direction: column;
  position: fixed;
  
  .logo-wrapper {
    width: 40px;
    margin: 41px auto 31px auto;
    display: flex;
    align-items: center;
  }

  .menu-list {
    list-style: none;
    
    & > a {
      padding: 12px 0px 12px 32px;
      color: var(--gray-200);
      font-size: var(--fontSize-14);
      font-weight: var(--weight-600);
      text-decoration: none;
      display: flex;
      align-items: center;
      transform-origin: center;
      transition: transform .1s;
      height: 45px;

      svg {
        margin-right: 10px;
        width: 14px;
        fill: var(--gray-400);

        path {
          fill: var(--gray-400);
        }
      }
    }

    .leftMenuBarMonitorIcon {
      path:first-child {
        fill: none;
      }
      path:first-child,
      path:nth-child(2) {
        stroke: var(--gray-400);
      }
      rect {
        fill: var(--gray-400);
      }
    }

    a.active {
      color: var(--white);
      svg, path {
        fill: var(--white);
      }

      .leftMenuBarMonitorIcon {
        path:nth-child(1),
        path:nth-child(2) {
          stroke: var(--white) !important;
        }
        rect {
          fill: var(--white);
        }
      }
    }

    a:hover {
      transform: translateX(8px) scale(1.1);
    }
  }
}

.menu-list-supplier-presentation-icon {
  width: 20px !important;
  height: 20px !important;
  margin-left: 8px;
  * {
    fill: var(--sunglowYellow) !important;
    fill: var(--red-500) !important;
  }
}