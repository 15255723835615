.bt-button {
  position: relative;
  font-size: 0.9em;
  font-weight: normal;
  /*letter-spacing: 0.02em;*/
  color: white;
  background-color: rgba(255, 94, 21, 1);
  cursor: pointer;
  transition: all 0.3s ease-out;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.bt-button.baseBtButtonSize {
  height: 40px;
  padding: 9px 17px;
}
.bt-button::-moz-focus-inner {
  border: 0;
  border: none;
  outline: none;
}
.bt-button:focus {
  border: none;
  outline: none;
}
.bt-button:hover {
  background-color: #EA580C;
}

.bt-button.white {
  color: var(--gray-700);
  border: 1px solid #D1D5DB;
  background-color: #FFFFFF;
}

.bt-button.white:hover {
  background-color: #F6F6F6;
}
.bt-button.blue {
  background-color: #001659;
}
.bt-button.blue:hover {
  box-shadow: rgba(0, 3, 84, 0.4) 0px 11px 19px 0px;
}
.bt-button.blue:active {
  background-color: #0817bd;
}
.bt-button.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.bt-button > div > .slot {
  display: block;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.bt-button .loader {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bt-button .loader > span {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1s infinite ease-in-out both;
  animation: sk-bouncedelay 1s infinite ease-in-out both;
}
.bt-button.white .loader > span {
  background-color: var(--gray-700);
}

.bt-button .loader .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.bt-button .loader .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
 }
  40% {
    -webkit-transform: scale(1);
 }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
 }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
 }
}
.bt-button.loader-show .loader {
  opacity: 1;
}
.bt-button.loader-show > div > .slot {
  opacity: 0;
}
.bt-button div {
  padding: 0;
  border: none;
  font: inherit;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  outline-style: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: inherit;
  display: inherit;
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  cursor: inherit;
  touch-action: manipulation;
  width: 100%;
}
_:-ms-fullscreen, :root .bt-button {
  border-radius: 0;
}
@supports (-ms-ime-align: auto) {
  .bt-button {
    border-radius: 0;
 }
}

.bt-button .slot {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  gap: 5px;
}
.bt-button.dark {
  background-color: var(--gray-900);
  border: 1px solid var(--gray-900);
  color: var(--white);
  height: 32px;
  font-size: 14px;
}
.bt-button.dark,
.bt-button.gray {
  border-radius: 6px;
}
.bt-button.gray {
  background-color: var(--gray-50);
  border: 1px solid var(--gray-300);
  color: var(--gray-700);
  height: 32px;
  font-size: 14px;
}
.bt-button.gray:hover {
  background-color: var(--gray-100);
}
.bt-button.success {
  border: 1px solid var(--green-300);
  background-color: var(--green-100);
  color: var(--green-700);
}
.bt-button.success:hover {
  background-color: var(--green-200);
}
.bt-button.failure {
  border: 1px solid var(--red-300);
  background-color: var(--red-100);
  color: var(--red-700);
}
.bt-button.failure:hover {
  background-color: var(--red-200);
}
.bt-button.xxsBtButtonSize {
  height: 28px;
  padding: 12px 9px;
}

.bt-button.xxsBtButtonSize div {
  font-size: 12px;
}