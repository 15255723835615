.internTableContainer .MuiTableRow-root {
  border-bottom: 1px solid var(--gray-200);
}

.internChatMenuContainer {
  padding: 0px 30px;
}

.internTableContainer .MuiTableCell-head {
  color: var(--gray-900) !important;
  font-weight: 500;
  padding: 10px 16px !important;
  position: static;
  background-color: var(--gray-50) !important;
}

.internTableContainer .MuiTableBody-root .MuiTableRow-root:hover {
  background-color: hsla(0,0%,96%,.4)!important;
  cursor: pointer;
}

.internTableContainer .MuiTableBody-root .MuiTableRow-root .name {
  color: var(--gray-900);
  font-size: var(--fontSize-16);
  font-weight: var(--weight-600);
  display: flex;
  gap: 16px;
  align-items: center;
}

.internTableContainer .MuiTableBody-root .MuiTableRow-root .email {
  color: var(--gray-700);
  font-size: var(--fontSize-14);
  font-weight: var(--weight-400);
}
.internTableContainer .MuiTableBody-root .MuiTableRow-root .role {
  color: var(--gray-900);
  font-size: var(--fontSize-14);
  font-weight: var(--weight-600);
}

.internTableContainer .MuiTableCell-head:first-child {
  border-top-left-radius: 6px;
}
.internTableContainer .MuiTableCell-head:last-child {
  border-top-right-radius: 6px;
  width: 30px !important;
}
.internTableContainer tr.MuiTableRow-root:last-child {
  border: 0px;
}