.filter-header {
  width: 229px;
  height: 47px;
  background: #f6f6f6;
  border: 1px solid #e5e7eb;
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
}
.org-pagination {
  margin: 20px auto 0px auto;
  width: fit-content;
}
.column-date-end-calendar-position-fix{ 
  z-index: 100000; 
}
.custom-style-calender{
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
  -webkit-transform: translateY(-40%);
  right: 1%;
}

.salesOrgContractButton .slot {
  margin: 0;
}
