@import "helpers.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --gray-50: #F6F6F6;
  --gray-100: #F2F2F3;
  --gray-200: #E5E7EB;
  --gray-250: #C8C8C8;
  --gray-300: #D1D5DB;
  --gray-400: #9CA3AF;
  --gray-500: #6B7280;
  --gray-600: #4B5563;
  --gray-700: #374151;
  --gray-800: #1F2937;
  --gray-900: #111827;

  --brandBlack: #212637;

  --yellow-50: #FFFBEB;
  --yellow-100: #FEF3C7;
  --yellow-400: #FBBF24;
  --yellow-500: #F59E0B;
  --yellow-700: #B45309;
  --yellow-800: #92400E;

  --green-50: #ECFDF5;
  --green-100: #D1FAE5;
  --green-200: #A7F3D0;
  --green-300: #6EE7B7;
  --green-500: #10B981;
  --green-700: #047857;

  --cyan-100: #CFFAFE;
  --cyan-500: #06B6D4;
  --cyan-800: #155E75;
  
  --blue-100: #DBEAFE;
  --blue-800: #1E40AF;

  --purple-100: #EDE9FE;
  --purple-800: #5B21B6;

  --light-blue-50: #F0F9FF;
  --light-blue-100: #E0F2FE;
  --light-blue-200: #BAE6FD;
  --light-blue-300: #7DD3FC;
  --light-blue-600: #0284C7;
  --light-blue-700: #0369A1;
  --light-blue-800: #075985;

  --red-50: #FEF2F2;
  --red-100: #FEE2E2;
  --red-200: #FECACA;
  --red-300: #FCA5A5;
  --red-400: #FE5150;
  --red-500: #EF4444;
  --red-700: #B91C1C;
  --red-800: #991B1B;

  --orange-500: #FF5C00;
  --white: white;
  --darkBlue: #19408C;
  --darkBlue-2:#001659;
  --sunglowYellow: #FFCB44;
  --crayolaGreen: #38AF78;
  --amazonGreen: #347E5B;

  --indigo-600: #4F46E5;
  --indigo-200: #C7D2FE;
  --indigo-50: #EEF2FF;

  --primaryFont: "Inter";

  --weight-600: 600;
  --weight-500: 500;
  --weight-400: 400;


  --fontSize-10: 10px;
  --fontSize-12: 12px;
  --fontSize-14: 14px;
  --fontSize-16: 16px;
  --fontSize-18: 18px;
}

html {
  line-height: unset;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #F2F2F3;

  font-family: var(--primaryFont);
}

/* Style Goes Here */

.general--page--content {
  display: block;
  height: 100%;
  min-width: 1200px;
}

.general--page--content .route-chat {
  height: 100%;
}

.general--page--content .flex-router,
.general--page--content .flex-router stencil-route-switch,
.general--page--content .flex-router stencil-route {
  display: flex;
  height: 100%;
  width: 100%;
}

.general--page--content .industry-types > li {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.general--page--content .link-active > .industry-types > li {
  opacity: 0.2;
}

.general--page--content .link-active > .industry-types > li:hover {
  opacity: 1;
}

.general--page--content
  .link-active
  > .industry-types
  > li:hover
  bt-accordion-head
  bt-button {
  color: #001659;
}

.general--page--content .industry-types > li.link-active {
  opacity: 1;
}

.general--page--content .column-content {
  background-color: #fafafb;
  padding: 30px;
}

.general--page--content .menu-top {
  background-color: white;
  border-bottom: 1px solid #e2e2ea;
}

.general--page--content .menu-top ul,
.general--page--content .menu-top li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.general--page--content .menu-top ul,
.general--page--content .menu-top li {
  display: inline-block;
}

.general--page--content .menu-top ul:first-child,
.general--page--content .menu-top li:first-child {
  margin-left: 10px;
}

.general--page--content .menu-top bt-button {
  
  text-align: left;
  color: black;
  font-weight: 500;
  padding: 20px 30px;
  background-color: transparent;
  transition-duration: 0.15s;
  border-radius: 0;
}

.general--page--content .menu-top bt-button:hover {
  box-shadow: none;
  color: #ff5e15;
}

.general--page--content .menu-top bt-button:active {
  background-color: transparent;
  box-shadow: none;
}

.general--page--content .menu-top .link-active bt-button {
  color: #ff5e15;
}

.general--page--content .menu-left {
  background-color: white;
  border-right: 1px solid #e2e2ea;
  display: inline-block;
  height: 100%;
  min-height: 100vh;
}

.general--page--content .menu-left a {
  text-decoration: none;
  text-transform: none;
}

.general--page--content .menu-left hr {
  margin: 0;
  border: none;
  border-top: 1px solid #f1f1f5;
}

.general--page--content .menu-left ul,
.general--page--content .menu-left li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu-left .bt-slider {
  max-width: 254px;
  /* height: calc(100vh - 60px) !important; */
  height: auto !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.conv--wrapper .menu-left .bt-slider {
  height: calc(100vh - 60px) !important;
}

.conv--custom-wrapper .menu-left .bt-slider {
  height: calc(100vh - 102px) !important;
}

.menu-left .bt-slider::-webkit-scrollbar {
  width: 8px;
}

.general--page--content .menu-left .bt-slider.animate {
  transition: opacity 0.2s ease-out, width 0.4s ease-out;
  opacity: 1;
}

.menu-left .logo-wrapper {
  min-width: 210px;
}

.general--page--content .menu-left .logo-wrapper {
  padding: 11px 40px;
}

.general--page--content .menu-left .bt-button.add-button {
  white-space: nowrap;
  color: #001659;
}

.general--page--content .menu-left .bt-button.add-button svg {
  transition: all 0.2s ease-out;
  width: 20px;
  height: 20px;
}

.general--page--content .menu-left .bt-button.add-button:hover {
  background-color: #ff5e15;
  color: white;
}

.general--page--content .menu-left .bt-button.add-button:hover svg {
  fill: white;
}

.menu-left .bt-button > button > .slot {
  display: flex;
}

.general--page--content .menu-left .bt-button {
  display: block;
  border-radius: 0;
  text-align: left;
  color: #ff5e15;
  font-weight: 500;
  padding: 20px 40px;
  background-color: transparent;
}

.general--page--content .menu-left .bt-button:hover {
  box-shadow: none;
}

.general--page--content .menu-left .bt-button:active {
  box-shadow: none;
}

.general--page--content .menu-left .bt-button svg {
  margin-right: 15px;
  position: relative;
  top: 1px;
}

.general--page--content .menu-left .bt-button span {
  text-align: left;
  letter-spacing: 0.02em;
}

.general--page--content .menu-left .menu-list {
  margin-top: 20px;
}

.general--page--content .menu-left ul .bt-button {
  white-space: nowrap;
  color: #001659;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  padding: 10px 40px;
}

.general--page--content .menu-left ul .bt-button:hover {
  box-shadow: none;
  color: #ff5e15;
}

.general--page--content .menu-left ul .bt-button:active {
  background-color: transparent;
  box-shadow: none;
}

.general--page--content .menu-left ul .bt-button app-indicator {
  position: relative;
  top: -1px;
  margin-left: 6px;
}

.general--page--content .menu-left ul a.link-active .bt-button {
  color: #001659;
}

.general--page--content .menu-left ul a.link-active .bt-button span {
  border-bottom: 1px solid #ff5e15;
}

.general--page--content
  .menu-left
  bt-accordion-body-content
  a.link-active
  .bt-button {
  color: #ff5e15;
}

.general--page--content
  .menu-left
  bt-accordion-body-content
  a.link-active
  .bt-button
  span {
  border-bottom: none;
}

.general--page--content .menu-left .project-name {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #001659;
  
  font-size: 1.05em !important;
}

.general--page--content .menu-left .project-name svg {
  transition: all 0.2s ease-in-out;
}

.general--page--content .menu-left .project-name:hover svg {
  transform: translateX(-8px);
}

.general--page--content .menu-left .project-name span {
  white-space: nowrap;
  display: inline-block;
  margin-top: 3px;
}

.general--page--content .menu-left .add-button {
  display: block;
  font-size: 0.9em;
  padding-top: 20px;
  padding-bottom: 20px;
}

.general--page--content .menu-left .suppliers ul {
  border: 1px solid #e2e2ea;
  border-right: 0;
  -webkit-box-shadow: inset 0px 0px 20px -2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0px 0px 20px -2px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0px 0px 20px -2px rgba(0, 0, 0, 0.15);
}

.general--page--content .menu-left .suppliers ul bt-button {
  font-size: 0.75em;
  padding: 7px inherit;
}

.general--page--content .menu-left .suppliers ul li.supplier:first-child {
  margin-top: 10px;
}

.general--page--content .menu-left .suppliers bt-button.add-supplier {
  margin-top: -13px;
  white-space: nowrap;
  color: #001659;
  padding-top: 15px;
  padding-bottom: 15px;
}

.general--page--content
  .menu-left
  .suppliers
  bt-button.add-supplier.supplier-empty {
  margin-top: 0;
}

.general--page--content .menu-left .suppliers bt-button.add-supplier span {
  font-size: 0.9em;
  font-weight: 700;
}

.general--page--content .menu-left .suppliers bt-button.add-supplier svg {
  margin-right: 13px;
}

.general--page--content .menu-left .suppliers bt-button.add-supplier svg g {
  transition: all 0.2s ease-out;
}

.general--page--content .menu-left .suppliers bt-button.add-supplier:hover {
  background-color: #ff5e15;
  color: white;
}

.general--page--content
  .menu-left
  .suppliers
  bt-button.add-supplier:hover
  svg
  g {
  fill: white;
}

.general--page--content .menu-left .suppliers bt-button.add-mail-batch {
  margin-top: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  white-space: nowrap;
}

.general--page--content .menu-left .suppliers bt-button.add-mail-batch span {
  font-size: 0.9em;
  font-weight: 700;
}

.general--page--content .menu-left .suppliers bt-button.add-mail-batch .icon {
  font-size: 1.2em;
  transition: all 0.2s ease-out;
  color: #001659;
}

.general--page--content .menu-left .suppliers bt-button.add-mail-batch svg {
  width: 14px;
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.general--page--content .menu-left .suppliers bt-button.add-mail-batch svg g {
  fill: #001659;
}

.general--page--content
  .menu-left
  .suppliers
  bt-button.add-mail-batch:hover
  .icon {
  color: white;
}

.general--page--content
  .menu-left
  .suppliers
  bt-button.add-mail-batch:hover
  svg
  g {
  fill: white;
}

.general--page--content .menu-left .chat-colleagues {
  margin-top: 20px;
  white-space: nowrap;
}

.column-content {
  padding: 30px;
}

.column-content.grey-shade {
  background-color: #fafafb;
}

.card-information .headline-label {
  font-weight: var(--weight-500);
  font-size: var(--fontSize-14);
  color: var(--gray-700);
  line-height: 20px;
  margin-bottom: 4px;
  display: block;
}

.card-information .headline-info {
  font-size: 1.7em;
  font-weight: 400;
}

.card-header {
  margin-bottom: 30px;
}

.status-danger {
  color: #fe5150;
}

.employees .employee {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.employees .employee bt-avatar {
  font-size: 16px;
  width: 40px;
  height: 40px;
}
.employees .employee label {
  margin-left: 10px;
  font-size: 16px;
  color: #001659;
  text-transform: none;
  white-space: nowrap;
  max-width: calc(100% - 55px);
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Uploaded Files Tip */

bt-filetype,
bt-filetype .icon {
  display: inline-block;
}

bt-filetype .icon {
  width: 27px;
}

bt-filetype .description {
  vertical-align: top;
  display: inline-block;
  margin-left: 10px;
  margin-top: 2px;
}

.uploaded-files bt-filetype.small a {
  font-size: 0.85em;
}

.uploaded-files bt-filetype.small a img {
  width: 25px;
  display: block;
}

.uploaded-files bt-filetype.small a .description {
  margin-top: 0px;
}

.uploaded-files bt-filetype a {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  color: inherit;
  display: flex;
  align-items: center;
  border: 1px solid #f1f1f5;
  padding: 5px 10px 5px 10px;
  border-radius: 4px;
  margin-top: 20px;
  margin-right: 10px;
}

.uploaded-files bt-filetype a .icon {
  display: flex;
  align-items: center;
}

.uploaded-files bt-filetype a .icon img {
  max-width: 100%;
}

.uploaded-files bt-filetype a[href]:hover {
  color: #ff5e15;
}

.mb-0 {
  margin-bottom: 0px;
}

.card-information {
  margin-bottom: 30px;
}

app-label p {
  text-transform: none;
  letter-spacing: 0.5px;
  
  font-size: 16px;
  font-weight: 300;
}

app-label p:first-child {
  margin-top: 0px;
}

app-label p:last-child {
  margin-bottom: 0px;
}

/* App Chat Mail */

.btn-align-right .bt-anim:after {
  content: "";
  display: block;
  clear: both;
}

.btn-align-right bt-button {
  float: right;
}

.app-chat-mail-batch h2 {
  white-space: nowrap;
}

.app-chat-mail-batch .message label {
  display: block;
  margin-bottom: 10px;
}

.app-chat-mail-batch .message .message-wrapper {
  padding: 15px;
  width: 500px;
  border: 1px solid #e2e2ea;
}

.app-chat-mail-batch .message .message-wrapper [contenteditable] {
  min-height: 255px;
  max-height: 400px;
  overflow: auto;
}

.app-chat-mail-batch .message .signature {
  padding: 0;
  padding-top: 0;
}

.app-chat-mail-batch .message .signature:hover pre {
  border-color: #ff5e15;
}

.app-chat-mail-batch .message .signature pre {
  -webkit-transition-property: border-color;
  transition-property: border-color;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  position: relative;
  left: -6px;
  padding-left: 5px;
  border-left: 2px solid transparent;
  margin: 0;
}

.app-chat-mail-batch .message .message-wrapper {
  padding: 15px;
  max-width: none;
  width: 100%;
  border: 1px solid #e2e2ea;
  box-sizing: border-box;
}

.app-chat-mail-batch .btn-attach-wrapper {
  display: inline-block;
  cursor: pointer;
  margin-top: 10px;
  padding: 0 10px;
}

.app-chat-mail-batch .btn-attach-wrapper:hover svg {
  fill: #ff5e15;
}

.app-chat-mail-batch .btn-attach-wrapper:active svg {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.app-chat-mail-batch .btn-attach-wrapper svg {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  fill: #bdbdbd;
  width: 23px;
  height: 26px;
}

.app-chat-mail-batch .btn-attach-wrapper input {
  display: none;
}

.app-chat-mail-batch .upload-files-wrapper {
  display: inline;
}

.form-group input {
  width: 100%;
  border: 1px solid #e2e2ea;
  padding: 12px 20px;
  box-sizing: border-box;
}

.btn-send {
  padding: 12px 40px;
}

.btn-send .slot {
  display: flex;
  align-items: center;
}

.btn-send svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}

/* Checkboxes */

.app-select-industry-types {
  display: block;
  width: 100%;
  max-width: 1188px;
}

.app-select-industry-types.show-edit-tools btn-remove-industry {
  display: block !important;
}

.app-select-industry-types.show-edit-tools .colum-right {
  background-color: #ff5e15;
}

.app-select-industry-types.show-edit-tools bt-check {
  width: 100%;
}

.app-select-industry-types.show-edit-tools bt-check:hover .name {
  background-color: #f1f1f5;
}

.app-select-industry-types.show-edit-tools bt-check label {
  width: 100%;
  cursor: text;
}

.app-select-industry-types.show-edit-tools bt-check label:after {
  visibility: hidden;
  opacity: 0 !important;
}

.app-select-industry-types.show-edit-tools bt-check label .name {
  width: 100%;
}

.app-select-industry-types.show-edit-tools bt-check .checkmark {
  display: none;
}

.app-select-industry-types.show-edit-tools bt-check .placeholder {
  font-style: italic;
}

.app-select-industry-types.show-edit-tools bt-check .placeholder:after {
  display: block;
  content: "";
  width: 10%;
  height: 1px;
  background-color: #ff5e15;
}

.app-select-industry-types .buttons-wrapper bt-button,
.app-select-industry-types .float-right {
  float: right;
}

.app-select-industry-types .float-left {
  float: left;
}

.app-select-industry-types input {
  letter-spacing: 0.02em;
}

.app-select-industry-types li,
.app-select-industry-types ul {
  padding: 0;
  margin: 0;
}

.app-select-industry-types li {
  list-style-type: none;
}

.app-select-industry-types bt-check {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-size: 0.9em;
  padding-bottom: 0;
  width: 100%;
}

.app-select-industry-types bt-check .checkmark {
  pointer-events: none;
}

.app-select-industry-types bt-check label {
  letter-spacing: 0.05em;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  padding-bottom: 3px;
  display: inline-block;
}

.app-select-industry-types bt-check label:after {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 5px;
  position: relative;
  bottom: 10px;
  content: "";
  display: block;
  width: calc(100% - 15px);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  height: 1px;
  background-color: #ff5e15;
}

.app-select-industry-types bt-check label .name {
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  padding: 10px 10px;
  margin-left: -5px;
  display: inline-block;
}

.app-select-industry-types bt-check:hover label:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.app-select-industry-types bt-check[checked] label {
  color: #ff5e15;
}

.app-select-industry-types bt-check[checked] label:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.app-select-industry-types bt-check[checked] .checkmark {
  -webkit-animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.5s both;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.5s both;
}

.app-select-industry-types bt-check[checked] .checkmark__circle {
  -webkit-animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.app-select-industry-types bt-check[checked] .checkmark__check {
  -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards;
}

.app-select-industry-types .colum-left {
  width: 80%;
  background-color: #fff;
}

.app-select-industry-types .colum-left .content-left {
  padding: 50px 70px;
}

.app-select-industry-types .colum-left app-label {
  margin-top: 3.8em;
  display: block;
}

.app-select-industry-types .colum-left app-label:first-child {
  margin-top: 0;
}

.app-select-industry-types .colum-left .buttons-wrapper {
  margin-top: 50px;
}

.app-select-industry-types .colum-left bt-button.btn-edit,
.app-select-industry-types .colum-left bt-button.btn-edit-cancel,
.app-select-industry-types .colum-left bt-button.btn-edit-save {
  display: inline-block;
  background-color: transparent;
  color: #ff5e15;
  padding-left: 0;
}

.app-select-industry-types .colum-left bt-button.btn-edit-cancel:hover,
.app-select-industry-types .colum-left bt-button.btn-edit-save:hover,
.app-select-industry-types .colum-left bt-button.btn-edit:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.app-select-industry-types .colum-left bt-button.btn-edit-cancel:active,
.app-select-industry-types .colum-left bt-button.btn-edit-save:active,
.app-select-industry-types .colum-left bt-button.btn-edit:active {
  color: #e15311;
}

.app-select-industry-types .colum-left bt-button.btn-edit-cancel:after,
.app-select-industry-types .colum-left bt-button.btn-edit-save:after,
.app-select-industry-types .colum-left bt-button.btn-edit:after {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  content: "";
  display: block;
  width: 1%;
  height: 1px;
  background-color: #ff5e15;
}

.app-select-industry-types .colum-left bt-button.btn-edit-cancel:hover:after,
.app-select-industry-types .colum-left bt-button.btn-edit-save:hover:after,
.app-select-industry-types .colum-left bt-button.btn-edit:hover:after {
  width: 100%;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.app-select-industry-types .colum-left bt-button.btn-edit-cancel {
  color: #6a6e77;
}

.app-select-industry-types .btn-add {
  float: right;
}

.app-select-industry-types .btn-close {
  position: absolute;
  right: -20px;
  top: -20px;
  padding: 40px;
  cursor: pointer;
  opacity: 0.8;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.app-select-industry-types .btn-close:hover {
  opacity: 1;
}

.app-select-industry-types .colum-right {
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -ms-flex: 0 1 44%;
  flex: 0 1 44%;
  background-color: #001659;
}

.app-select-industry-types .colum-right .content-right {
  padding: 50px 70px;
}

.app-select-industry-types .colum-right .content-right .headline {
  margin-bottom: 5px;
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 400;
  display: block;
  color: #fff;
  letter-spacing: 0.1em;
}

.app-select-industry-types .colum-right .content-right h2 {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 3.4em;
  line-height: 1.5em;
  margin-bottom: 0.65em;
}

.app-select-industry-types .colum-right .content-right p {
  color: #fff;
  margin: 0;
  margin-top: -10px;
  letter-spacing: 0.1em;
  line-height: 2.2em;
}

.app-select-industry-types .btn-add-item {
  cursor: pointer;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.app-select-industry-types .btn-add-item svg circle {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.app-select-industry-types .btn-add-item:hover svg circle {
  fill: #d64343;
}

.app-select-industry-types .btn-add-item:active {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.app-select-industry-types .checkmark {
  position: absolute;
  top: 50%;
  margin-top: -13px;
  margin-left: -4px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  stroke-width: 4;
  stroke: #ff5e15;
  stroke-miterlimit: 10;
}

.app-select-industry-types .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 3;
  stroke-miterlimit: 10;
  stroke: #ff5e15;
  fill: none;
}

.app-select-industry-types .checkmark__check {
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
}

.app-select-industry-types .industry-items {
  margin-right: 30px;
}

.app-select-industry-types .industry-items .industry-item {
  position: relative;
}

.app-select-industry-types .industry-items .industry-item btn-remove-industry {
  cursor: pointer;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  display: none;
  opacity: 0;
  position: absolute;
  padding-right: 10px;
  right: -20px;
  z-index: 1;
  top: 48%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: " ";
  background-image: url(./assets/svg/delete-ring.svg);
  height: 14px;
  width: 14px;
  background-repeat: no-repeat;
}

:root bt-check[checked] .checkmark__check,
:root bt-check[checked] .checkmark__circle,
:root bt-check.checked .checkmark__check,
:root bt-check.checked .checkmark__circle,
_:-ms-fullscreen .checkmark__check,
_:-ms-fullscreen .checkmark__circle {
  stroke-dashoffset: 0;
}

@-webkit-keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes scale {
  0%,
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  50% {
    -webkit-transform: scale3d(1.01, 1.01, 1);
    transform: scale3d(1.01, 1.01, 1);
  }
}

@keyframes scale {
  0%,
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  50% {
    -webkit-transform: scale3d(1.01, 1.01, 1);
    transform: scale3d(1.01, 1.01, 1);
  }
}

.industry-items li.industry-item {
  padding-right: 20px;
  margin-right: 20px;
}

.l-edit {
  color: #ff6822;
  font-size: 12px;
  text-transform: uppercase;
  
  display: block;
  text-align: right;
  letter-spacing: 0.5px;
}

/* Auto Suggest */

.c-autosuggest > div[class] {
  background-color: transparent;
  border: 0px;
  border-bottom: 1px solid #e2e2ea;
  margin-top: 10px;
  box-shadow: none;
}

.c-autosuggest > div[class] > :first-child {
  background: transparent;
  height: 56px;
  padding-left: 0px;
}
/*
.c-autosuggest > div[class] > :first-child > :first-child:not(.custom-dropdown-page-task__option){
  font-weight: bold;
  background-color: transparent;
  font-size: 3em;
  color: #001659;
  text-transform: none;
  margin-left: 0px;
}
*/
.c-autosuggest > div[class]:first-of-type > :first-child > :first-child input {
  width: 100% !important;
  margin-top: 0px;
}


.c-autosuggest > div[class]:first-of-type > :last-child > :first-child:not(.custom-dropdown-page-task__option) {
  display: none;
}

.creditInfoSelector {
  margin-top: 10px;
}

.c-autosuggest > div[class]:first-of-type > :last-child > :last-child:not(.custom-dropdown-page-task__option) {
  background-image: url(./assets/images/dropdown-icon.png);
  background-size: 15px;
  background-position: center;
  width: 30px;
}

.c-autosuggest > div[class]:first-of-type > :last-child > :last-child > svg {
  display: none;
}

.section-strategy .left--style {
  border-left: 0px;
}

.section-strategy .left--style p {
  font-size: 16px;
  line-height: 30px;
}

.left--style,
.right--style {
  padding: 15px;
  margin: 0px !important;
  width: calc(50%) !important;
  flex: 0 0 50%;
}

.left--style {
  word-break: break-word;
}

.app-project-settings .employee {
  margin-top: 10px;
}

.app-project-settings .employee bt-avatar {
  font-size: 14px;
  width: 40px;
  height: 40px;
}

.app-project-settings .employee > label {
  margin-left: 10px;
}

#chat-msg-cn-wrapper.chat-msg-cn-wrapper {
  /*height: calc(100vh - 102px);*/
}

.page-projects-single-inbox {
  height: 100%;
}

#chat-msg-cn-wrapper.chat-msg-cn-wrapper.only--supplier {
/*  height: calc(100vh);*/
}

#chat-msg-cn-wrapper#chat-msg-cn-wrapper.chat-msg-cn-wrapper.info--style .info--panel {
  z-index: 2;
  background-color: white;
  flex: 0 0 290px;
}

#chat-msg-cn-wrapper.chat-msg-cn-wrapper.info--style #uploads-gallery-wrapper.uploads-gallery-wrapper {
  right: 0px;
  z-index: 1;
}

.chat-message-window {
  flex-grow: 1;
  overflow-x: hidden;
}
.chat-message-window .chat-message-scroller {
  width: 100%;
  height: calc(100vh - 326px);
  overflow: auto;
  background-color: #fafafb;
}

.chat-message-window .chat-message-scroller.style--2 {
  height: calc(100vh - 255px);
}

.overflow-listq {
  overflow: auto;
  height: 450px;
}

.justify-center {
  justify-content: center;
}

#uploads-gallery-wrapper.uploads-gallery-wrapper,
.floating-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 200px;
  max-width: 280px;
  height: 100%;
  transform: translateX(100%);
  transition: all 0.3s;
  overflow: visible !important;
  box-shadow: none;
  background-color: #fafafb;
}

.floating-sidebar .app-project-settings {
  min-height: 100%;
}

.chat-message-window #uploads-gallery-wrapper.uploads-gallery-wrapper,
.floating-sidebar {
  box-shadow: none !important;
}

#uploads-gallery-wrapper.uploads-gallery-wrapper.open,
.floating-sidebar.open {
  z-index: 1;
  transform: translateX(0%);
  box-shadow: -7px 2px 5px 0px rgba(0, 0, 0, 0.03);
}

.chat-message-window #uploads-gallery-wrapper.uploads-gallery-wrapper.open {
  box-shadow: -7px 2px 5px 0px rgba(0, 0, 0, 0.03) !important;
}

.app-chat-write-message .uploads-list {
  z-index: 3;
}

.app-chat-write-message .bt-grid-main {
  z-index: 4 !important;
}

#uploads-gallery-wrapper.uploads-gallery-wrapper .uploads-gallery-files {
  padding: 15px;
  overflow: auto;
}

span.gallery-files-toggler {
  position: absolute;
  top: 0px;
  left: -30px;
  padding: 10px;
  background-color: #ff5e15;
  border-radius: 4px 0px 0px 4px;
  cursor: pointer;
}

span.gallery-files-toggler svg {
  fill: #fff;
  transition: all 0.3s;
}

span.gallery-files-toggler svg path {
  fill: #fff;
}

.app-chat-mail-batch .colum-left.pp-col-left {
  max-width: 55%;
  flex: 0 0 55%;
}

.app-chat-mail-batch .colum-right.pp-col-right {
  max-width: 45%;
  flex: 0 0 45%;
}

.app-chat-mail-batch .colum-right.pp-col-right .content-right h2 {
  white-space: normal;
}

.modal--lg--style {
  width: 1150px;
}

.modal--lg--style .content-left {
  height: 690px;
  box-sizing: border-box;
}

.modal--lg--style .content-left .mx-content {
  height: 520px;
}

.modal--lg--style .content-left .mx-content.no--pagination {
  height: 590px;
  overflow: auto;
  box-sizing: border-box;
}

.modal--lg--style .content-left .mx-content.overflow-s {
  overflow: auto;
}

.modal--lg--style .content-right {
  height: 690px;
  box-sizing: border-box;
}

.modal--lg--style .content-left.allow-expand,
.modal--lg--style .content-right.allow-expand {
  height: auto;
}

.ff-price-type .app-select-industry-types {
  padding-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.ff-price-type {
  flex: 0 0 auto;
}

.ff-price-type .bt-column {
  display: block;
  flex: 0 0 auto;
}

.ff-price-type .app-select-industry-types .checkmark {
  margin-top: -18px;
}

.fm-group {
  margin-bottom: 20px;
}

.grid-demand {
  width: auto;
}

.grid-demand .bt-row {
  flex-grow: unset;
}

.grid-demand .app-select-industry-types {
  padding-left: 0px;
}

.grid-demand .fm-actions .bt-column {
  margin-right: 15px;
}

.grid-demand .fm-actions .bt-column:last-child {
  margin-right: 0px;
}

.fm-actions svg {
  width: 18px;
  height: 18px;
  display: block;
}

.align-item-center {
  align-items: center;
}

.dma-action.bt-search input {
  padding-left: 15px;
  padding-right: 0px;
}

/* Datepicker */
div.react-datepicker-popper{
  z-index: 99999;
}
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper .react-datepicker__navigation--next {
  border-left-color: #ff5e15;
  opacity: 0.7;
}

.react-datepicker-popper .react-datepicker__navigation--previous {
  border-right-color: #ff5e15;
  opacity: 0.7;
}

.react-datepicker-popper .react-datepicker__navigation--next:hover {
  border-left-color: #ff5e15;
  opacity: 1;
}

.react-datepicker-popper .react-datepicker__navigation--previous:hover {
  border-right-color: #ff5e15;
  opacity: 1;
}

.react-datepicker__day--outside-month {
  color: #c7c7c7;
}

.react-datepicker__day--today {
  color: #ff5e15 !important;
  background-color: transparent !important;
  font-weight: 400;
}

.react-datepicker__day--today.react-datepicker__day--selected {
  background-color: transparent;
  color: #ffffff !important;
}

.react-datepicker__day:before {
  content: "";
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  border-radius: 40px;
  z-index: -1;
  transition: all 0.3s;
}

.react-datepicker__day {
  position: relative;
  z-index: 5;
  line-height: 30px !important;
  letter-spacing: 0;
}

.react-datepicker__day.react-datepicker__day--selected:before {
  background-color: #ff5e15;
}

.react-datepicker__day:hover:before {
  background-color: #f0f0f0;
}

.react-datepicker__day:hover,
.react-datepicker__day--selected {
  background-color: transparent !important;
}

.react-datepicker__day.react-datepicker__day--selected:hover:before {
  background-color: #ff5e15;
}

.bt-modal .column-content {
  min-height: auto;
}

.column-content {
  min-height: calc(100vh - 125px);
}

.page-internal-chat-member {
  box-sizing: border-box;
}

ol.progtrckr {
  background-color: #ffffff;
}

ol.progtrckr + div {
  background-color: #fafafb;
  min-height: calc(100vh - 160px);
}

.section-strategy:after {
  content: "";
  clear: both;
  display: block;
}

.section-strategy textarea {
  border: 1px solid #e2e2ea;
  background-color: #fff;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
}

.page-lumna textarea {
  border: 1px solid #e2e2ea !important;
  background-color: #fff;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
}

.page-lumna .bt-button {
  margin-top: 30px;
}

.section-strategy .right-box--style,
.section-strategy .left-box--style {
  padding-left: 0px;
}

.page-lumna .column-content label {
  margin-bottom: 10px;
  display: block;
}

.text-right {
  text-align: right;
}

.clearfix:after {
  content: "";
  clear: both;
  display: block;
}

.checky--listing {
  max-height: 520px;
  overflow: auto;
}

.content-left .content-left {
  padding: 0px !important;
  height: auto;
}

.error.hide {
  display: none;
}

/***************Abdullah editing************/
.fa-2 {
  font-size: 2em;
}

.fa-3 {
  font-size: 4em;
}

.fa-4 {
  font-size: 7em;
}

.fa-5 {
  font-size: 12em;
}

.fa-6 {
  font-size: 20em;
}

.page-contract .app-project-overview .overview-item-wrapper .item {
  width: 85%;
  flex: 0 0 85%;
}

.page-contract .app-project-overview .app-project-overview-item {
  width: calc(100% - 200px);
  float: left;
}

.page-contract .contract-icons {
  float: left;
  padding: 10px;
  max-width: 200px;
}

.page-contract .contract-icons .upload-icon {
  float: left;
}

.page-contract .contract-icons .signed-icon {
  float: left;
  margin-left: 5px;
}

.page-contract .contract-icons .signed-icon .fa {
  color: #5cb85c;
}

.page-contract .contract-icons .signed-icon .fa:hover {
  color: darkgreen;
}

.page-contract .contract-icons .upload-icon .fa {
  color: grey;
}

.page-contract .contract-icons .upload-icon .fa:hover {
  color: darkgrey;
}

.modal-contract-uploader .save-btn {
  margin: 0 auto;
  display: table;
  margin-top: -20px;
}

.modal-contract-uploader .btn-attach-column {
  cursor: pointer;
  padding: 0 20px;
}
.modal-contract-uploader .btn-attach-column:hover svg {
  fill: #ff5e15;
}
.modal-contract-uploader .btn-attach-column:active svg {
  transform: scale(0.9);
}
.modal-contract-uploader .btn-attach-column svg {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  fill: #bdbdbd;
  width: 23px;
  height: 26px;
}
.modal-contract-uploader .btn-attach-column input {
  display: none;
}

.logo-wrapper img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 100%;
  max-width: 100%;
}

.bt-check {
  display: inline-block;
  position: relative;
  font-size: 0.9em;
  padding-bottom: 0;
  width: 100%;
}

.bt-check .checkmark {
  pointer-events: none;
}

.bt-check label {
  letter-spacing: 0.05em;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  padding-bottom: 3px;
  display: inline-block;
}

.bt-check label:after {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 5px;
  position: relative;
  bottom: 10px;
  content: "";
  display: block;
  width: calc(100% - 15px);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  height: 1px;
  background-color: #ff5e15;
}

.bt-check label .name {
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  padding: 10px 10px;
  margin-left: -5px;
  display: inline-block;
}

.bt-check:hover label:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.bt-check[checked] label,
.bt-check.checked label {
  color: #ff5e15;
}

.bt-check[checked] label:after,
.bt-check.checked label:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.bt-check[checked] .checkmark,
.bt-check.checked .checkmark {
  -webkit-animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.5s both;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.5s both;
}

.bt-check[checked] .checkmark__circle,
.bt-check.checked .checkmark__circle {
  -webkit-animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.bt-check[checked] .checkmark__check,
.bt-check.checked .checkmark__check {
  -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards;
}

.bt-check .checkmark {
  pointer-events: none;
  position: absolute;
  top: 50%;
  margin-top: -13px;
  margin-left: 10px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  stroke-width: 2;
  stroke: #ff5e15;
  stroke-miterlimit: 10;
}

.bt-check .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #ff5e15;
  fill: none;
}

.bt-check .checkmark__check {
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
}

.bt-check label {
  vertical-align: top;
  letter-spacing: 0.05em;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  padding-bottom: 3px;
  display: inline-block;
  margin-bottom: 0px;
}

.bt-check label:after {
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  margin: 0 5px;
  position: relative;
  bottom: 10px;
  content: "";
  display: block;
  width: calc(100% - 15px);
  transform: scaleX(0);
  height: 1px;
  background-color: #ff5e15;
}

.bt-check:hover label:after {
  transform: scaleX(1);
}

.bt-check .checkmark {
  width: 50px;
}

.bt-check label:after {
  display: none;
}

.bt-check[checked] label {
  color: #ff5e15;
}

/* Tasks */

.ac-task-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 30px;
}

.ac-task-list .ac-task {
  width: calc(100% - 30px);
  margin: 15px 15px;
  border-radius: 4px;
}

.ac-task h3 {
  margin-top: 0px;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  vertical-align: middle;
  color: #000;
}

.ac-task h3 a {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.ac-task h3 small {
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #ff5e15;
  font-weight: 100;
  margin-left: 10px;
}

.ac-task-item {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.act-header {
  display: flex;
}

.act-header .actions {
  margin-left: auto;
}

.act-header .actions .ac-edit {
  max-width: 22px;
}

.act-header .actions .ac-add {
  width: 16px;
  height: 16px;
}

.ac-task-item .checkmark {
  position: relative;
  top: 0;
  left: 0;
  margin-left: 0px;
  flex: 0 0 22px;
  width: 22px;
  height: 22px;
  margin-top: 0px;
  display: block;
  margin-right: 12px;
  align-self: flex-start;
}

.ac-task-item .bt-check .checkmark .checkmark__check {
  visibility: hidden;
  transition: all 0.3s;
}

.ac-task-item .bt-check.checked .checkmark .checkmark__check {
  visibility: visible;
  stroke: #fff;
  -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.ac-task-item .bt-check .checkmark .checkmark__circle {
  transition: all 0.3s;
  stroke: #ff5e15;
  fill: transparent;
  stroke-dashoffset: 0;
}

.ac-task-item .bt-check.checked .checkmark .checkmark__circle {
  stroke: #ff5e15;
  fill: #ff5e15;
}

.ac-task-item .bt-check.checked .task-name {
  text-decoration: line-through;
  color: #92929d;
}

.task-name .tt-title {
  margin-right: 15px;
  width: 100%;
}

.task-detail .actions {
  display: flex;
  align-items: flex-start;
}

.task-detail .actions img:last-child {
  margin-right: 0px;
}

.task-checky {
  width: 100%;
}

.task-detail {
  width: 100%;
  padding-left: 35px;
  margin-top: 4px;
  display: flex;
  align-items: center;
}

.task-detail .actions img {
  max-width: 20px;
  margin-right: 8px;
  cursor: pointer;
}

.ac-task-item .bt-check {
  width: 100%;
  display: block;
}

.ac-task-item .bt-check label {
  padding-bottom: 0px;
  display: flex;
  align-items: center;
}

.ac-task-item .task-date img {
  max-width: 18px;
  position: relative;
  margin: 0px 4px 0px 0px;
}

.ac-task-item .task-date {
  font-size: 12px;
  color: #92929d;
  align-items: center;
}

.task-name {
  color: #001659;
  text-transform: none;
  letter-spacing: 0.2px;
  display: flex;
  width: 100%;
}

.task-detail .task-assignee .tn-avatar img {
  max-width: 13px;
  position: relative;
  top: 2px;
  margin: 0px 7px 0px 0px;
}

.task-date span {
  min-width: fit-content;
}

.task-detail > .task-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 30px 0px 13px;
}

.task-detail .task-assignee {
  display: inline-flex;
  font-size: 12px;
  color: #92929d;
  align-items: center;
}

.task-detail .task-assignee a {
  color: #92929d;
}

.app-chat-mail-batch .slides input + input {
  margin-left: 4px;
}

.modal-user-areas .supplier-wrapper .bt-check label {
  margin-bottom: 0px;
}

.modal-user-areas .bt-check {
  margin-bottom: 0px;
  padding: 10px;
}

.modal-user-areas .bt-check.user-avatar.list--selected {
  background-color: #f1f1f1;
}

.modal-user-areas .supplier-wrapper {
  margin-right: 0px;
}

.modal-user-areas .bt-check .l-edit {
  margin-left: auto;
  pointer-events: none;
}

.modal-user-areas .bt-check .checkmark.remove--icon {
  position: static;
  stroke-width: 17px;
  margin-top: 0px;
  display: block;
  margin-left: 0;
  width: 38px;
  height: 28px;
}

.modal-user-areas .bt-check .checkmark.remove--icon .checkmark__circle {
  stroke-dasharray: 1700;
  stroke-dashoffset: 1700;
  stroke-width: 17px;
}

.modal-user-areas .bt-check .checkmark.remove--icon .checkmark__check {
  stroke-dasharray: 378;
  stroke-dashoffset: 378;
}

.modal-user-areas .supplier-wrapper .bt-check label .name {
  padding: 0px;
}

.contract-icons img {
  width: 30px;
  height: auto;
  margin-right: 10px;
  vertical-align: middle;
}

.modal-user-areas .supplier-wrapper .bt-check label {
  display: flex;
  align-items: center;
}

.app-chat-mail-batch.modal-user-areas .default-m-areas .content-left {
  padding: 50px 40px;
}

.app-chat-mail-batch.modal-user-areas .default-m-areas .bt-check {
  padding: 0px;
}

.app-chat-mail-batch.modal-user-areas .default-m-areas .bt-check label {
  color: #fff;
}

.app-chat-mail-batch.modal-user-areas .default-m-areas .bt-check .name {
  overflow: hidden;
}

.menu-left bt-button > button > .slot {
  display: flex;
  align-items: center;
}

.app-chat-message .overflow .body > span {
  display: block;
  word-break: break-word;
}

.app-chat-mail-batch.modal-user-areas .pp-col-left,
.app-chat-mail-batch.modal-user-areas .pp-col-right {
  max-width: 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.app-chat-mail-batch.modal-user-areas .default-m-areas .colum-left {
  background-color: #001659;
}

.nv-action {
  position: relative;
}

.nv-action.top .btn-dangerous {
  top: 12px;
}

.nv-action .btn-dangerous {
  position: absolute;
  right: 18px;
  top: calc(50% - 9px);
  max-width: 18px;
  transform: translateX(30px);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.nv-action .btn-dangerous img {
  max-width: 100%;
  display: block;
  width: 20px;
}

.nv-action:hover .btn-dangerous:not(.custom-project-remove-supplier) {
  transform: translateX(0px);
  opacity: 1;
  pointer-events: all;
  z-index: 9;
}

.suppliers.custom--listing li.supplier:hover .custom-project-remove-supplier{
  
  transform: translateX(0px);
  opacity: 1;
  pointer-events: all;
  z-index: 9;
}

.suppliers.custom--listing li.supplier .custom-project-remove-supplier {
  top: 8px;
}

span.user-actop:before {
  content: "|";
  color: #91929d;
  padding-right: 7px;
  padding-left: 7px;
}

span.user-actop.nosp:before {
  display: none;
}


.cont-uploader-wrapper .cont-attacher {
  display: block;
  line-height: 0px;
}

.cont-uploader-wrapper .cont-attacher input[type="file"] {
  margin: 0;
  height: 0;
  opacity: 0;
}

.cont-uploader-wrapper .cont-attacher:hover svg {
  fill: #ff5e15;
}
.cont-uploader-wrapper .cont-attacher:active svg {
  transform: scale(0.9);
}
.cont-uploader-wrapper .cont-attacher svg {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  fill: #bdbdbd;
  width: 23px;
  height: 26px;
  cursor: pointer;
}

.cont-uploader-wrapper .cont-attacher .btn-attach {
  display: block;
}

.cont-uploader-wrapper .uploaded-files bt-filetype {
  display: flex;
  align-items: center;
}

.cont-uploader-wrapper .uploaded-files bt-filetype a {
  margin-top: 0px;
  align-items: center;
}

.cont-uploader-wrapper .uploaded-files bt-filetype svg {
  margin-right: 10px;
}

span.file-acceptance {
  display: block;
  font-size: 13px;
  text-transform: initial;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin-bottom: 10px;
  color: #9399a7;
}

.send-notes {
  font-weight: 500;
  font-size: 14px;
  display: block;
  margin-top: 20px;
}

/* App Loader */

.app-loader {
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  pointer-events: none;
  opacity: 0;
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.app-loader:before {
  position: absolute;
  content: "";
  width: 110px;
  height: 110px;
  background-image: url(./assets/images/logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateY(-100%);
}

.app-loader > span {
  width: 20px;
  height: 20px;
  background-color: #ff5e15;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1s infinite ease-in-out both;
  animation: sk-bouncedelay 1s infinite ease-in-out both;
}
.app-loader .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.app-loader .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.app-loader.loader-show {
  opacity: 1;
}

.js-more-info {
  max-height: 272px;
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
  transition: all 0.3s;
}

.js-more-info.expanded {
  max-height: 1000px;
  margin-bottom: 0px;
}

.js-more-info:not(.expanded):after {
  content: "Mer information";
  width: 100%;
  position: absolute;
  bottom: 0;
  background: -moz-linear-gradient(
    top,
    rgba(250, 250, 251, 0.5) 0%,
    rgba(250, 250, 251, 1) 60%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(250, 250, 251, 0.5) 0%,
    rgba(250, 250, 251, 1) 60%
  );
  background: linear-gradient(
    to bottom,
    rgba(250, 250, 251, 0.5) 0%,
    rgba(250, 250, 251, 1) 60%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fafafb', endColorstr='#fafafb',GradientType=0 );
  padding: 35px 20px 0px;
  text-align: center;
  text-transform: uppercase;
  color: #ff5e15;
  font-weight: 600;
  cursor: pointer;
}

.app-project-settings .employee {
  font-size: 16px;
}

.section--quotation .app-chat-mail-batch .message .message-wrapper {
  width: 100%;
}

.ff-tab-row {
  display: flex;
  flex-wrap: wrap;
  white-space: normal;
}

.ff-tab-row > a {
  margin-left: 10px;
}

.app-project-overview-item .ff-tab-row bt-popover.supplier svg {
  opacity: 1;
}

.app-project-overview-item.status-success
  table.suppliers
  tbody
  td
  .ff-tab-row
  svg {
  opacity: 1;
}

.extra-text {
  font-size: 0px;
}

.__react_component_tooltip ul {
  padding-left: 20px;
  margin: 0;
}

.__react_component_tooltip {
  padding: 10px;
  min-width: 120px;
}

.page-response-mesasge .bt-row .bt-column {
  width: 50%;
  flex: 0 0 50%;
}

.page-response-mesasge {
  min-height: 100vh;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAPklEQVQoU43MuQ0AIAwEQRxf/6VeDDISiMffxqOVVohkl8wpAiAhXEhnLjyRC19kQgt90EMXjNCGGZqwghQO3gwn6VA4+MAAAAAASUVORK5CYII=")
    repeat;
}

.page-response-mesasge .bt-grid {
  min-height: 100vh;
}

.page-response-mesasge .bt-row {
  align-items: center;
}

.page-response-mesasge img {
  max-width: 560px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.rm-wrapper {
  padding: 40px;
  max-width: 500px;
}

.rm-wrapper .title {
  font-size: 110px;
  margin: 0px;
  color: #001659;
}

.rm-wrapper .subtitle {
  color: #ff5e15;
  font-size: 23px;
  margin-top: 5px;
  line-height: 32px;
}

.rm-wrapper .description {
  font-size: 16px;
  font-weight: 400;
  color: #5f5f5f;
  margin-top: 0px;
  margin-bottom: 40px;
}

.rm-wrapper a {
  color: #fff;
  text-decoration: none;
}

.page-browser-support {
  background-color: #001659;
  color: #fff;
  min-height: 100vh;
  background-image: url("./assets/images/bg.png");
  background-repeat: repeat;
  text-align: center;
}

.page-browser-support h2.title {
  font-size: 52px;
  margin-top: 90px;
  margin-bottom: 5px;
}

.page-browser-support .description {
  font-size: 23px;
  margin-bottom: 70px;
  color: #c0d1ff;
}

.page-browser-support .browsers-list {
  display: flex;
  justify-content: center;
}

.page-browser-support .browsers-list a {
  max-width: 100px;
  width: 100px;
  margin: 0 30px;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  display: block;
}

.page-browser-support .browsers-list a:hover img {
  transform: scale(1.08);
}

.page-browser-support .browsers-list a span {
  display: block;
  margin-top: 12px;
}

.page-browser-support .browsers-list a img {
  max-height: 100px;
  max-width: auto;
  transition: all 0.3s;
}

.page-browser-support .bt-grid {
  min-height: 100vh;
}
.color-orange {
  color: #ff5e15;
}
.pointer-curser {
  cursor: pointer;
}

.page-projects .menu-left ul a bt-button button {
  transition: all 0.3s;
}

.menu-left ul a bt-button button:before,
.menu-left ul span bt-button button:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -16px;
  width: 8px;
  height: 2px;
  background-color: #ff6722;
  pointer-events: none;
  z-index: 99;
  transition: all 0.3s;
  transform: scaleX(0);
  transform-origin: right;
}

.menu-left ul .link-active bt-button button:before {
  transform: scaleX(1);
}

.menu-left ul .link-active bt-button button {
  position: relative;
}

.menu-left ul a.link-active bt-button span {
  border: none !important;
  color: #ff6722;
  transition: inherit;
}

.quo-preview-wrapper .av-list > div {
  flex: 0 0 calc(50% - 10px);
  margin-right: 10px;
  max-width: calc(50% - 10px);
}

.av-list {
  display: flex;
  flex-wrap: wrap;
}

.quo-preview-wrapper .av-list > div bt-avatar {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  font-size: 14px;
}

.quo-preview-wrapper .av-list > div > span {
  font-size: 16px;
  color: #5c5c5c;
}

.quo-preview-wrapper .av-list label {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.app-chat-mail-batch .quo-preview-wrapper .user-avatar {
  margin-bottom: 15px;
  display: flex;
}

.app-chat-mail-batch .quo-preview-wrapper .user-avatar .info {
  display: block;
  margin-left: 10px;
  margin-top: 5px;
  max-width: calc(100% - 56px);
}

.app-chat-mail-batch .quo-preview-wrapper .user-avatar p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quo-preview-wrapper .fm-group > label {
  margin-bottom: 10px;
  display: block;
}

.quo-preview-wrapper .uploaded-files bt-filetype a {
  margin-top: 0px;
}

.quo-preview-wrapper .listing li {
  align-items: center;
}

.quo-preview-wrapper .listing li:before {
  line-height: 13px;
}

.quo-preview-wrapper .item-overflow {
  margin-top: 8px;
}

.listing {
  padding: 0;
  list-style: none;
  margin: 0;
}

.listing li {
  counter-increment: list;
  font-size: 16px;
  margin-bottom: 7px;
  display: flex;
}

.listing li:before {
  content: counter(list) "-";
  margin-right: 8px;
  font-size: 16px;
  line-height: 14px;
  vertical-align: middle;
  color: #bdbdbd;
}

.listing.bullet--style li:before {
  content: "-";
}

.listing.bullet--style li {
  margin-bottom: 20px;
}

.section-strategy .listing {
  color: #73767c;
}

.section-strategy .left--style p {
  color: #73767c;
}

.section-strategy .listing li:before {
  color: #73767c;
}

.section-strategy .public-DraftEditorPlaceholder-root {
  color: #000000;
}

.section-strategy
  .public-DraftEditorPlaceholder-root.public-DraftEditorPlaceholder-hasFocus {
  color: #73767c;
}

.quo-preview-wrapper .message-wrapper p {
  font-size: 16px;
  color: #5c5c5c;
  margin: 0px;
}

.quo-preview-wrapper .uploaded-files svg {
  display: none;
}

.quo-preview-wrapper .fm-group {
  margin-bottom: 30px;
}

.quo-preview-wrapper {
  color: #5c5c5c;
}

.page--quotation .container--quo .message .message-wrapper {
  width: 100%;
}

.page--quotation .container--quo {
  max-width: 1188px;
}

.page--quotation .industry-item bt-check .checkmark .checkmark__check {
  visibility: hidden;
  transition: all 0.3s;
}

.page--quotation .industry-item bt-check[checked] .checkmark .checkmark__check {
  visibility: visible;
  stroke: #fff;
  -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.page--quotation .industry-item bt-check .checkmark .checkmark__circle {
  transition: all 0.3s;
  stroke: #ff5e15;
  fill: transparent;
  stroke-dashoffset: 0;
}

.page--quotation
  .industry-item
  bt-check[checked]
  .checkmark
  .checkmark__circle {
  stroke: #ff5e15;
  fill: #ff5e15;
}

.error.floating-error {
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  text-align: left;
  font-size: 12px;
  bottom: initial;
  margin: 0px;
  
  letter-spacing: 0.3px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.error.floating-error p {
  margin: 0;
}

.sidebar-input {
  width: 100%;
  padding: 15px 0px 15px 50px;
  font-weight: 500;
}

.sidebar-input::placeholder {
  color: #92929d;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.sbi-wrapper {
  position: relative;
}

.sbi-wrapper svg {
  position: absolute;
  top: calc(50% - 9px);
  left: 22px;
  width: 16px;
  height: 16px;
}

.app-chat-mail-batch .colum-right .selected-supplier-users-wrapper label {
  color: #fff;
}

.menu-top li a bt-button {
  max-height: 55px;
  padding: 18px 25px !important;
}

.area--message .message-text {
  display: block;
}

.page--quotation bt-check .checkmark {
  right: 0px;
}

.page--quotation bt-check .name {
  padding-right: 32px !important;
}

.page--quotation bt-check label:after {
  width: calc(100% - 37px);
}

.step-progress .bg {
  position: absolute;
  left: 0;
  bottom: 0px;
  z-index: 1;
}

.step-progress .bg img {
  width: 100%;
  max-width: 400px;
}

.page-projects-single > div:not(.bg),
.section-strategy > .bt-grid,
.page-lumna > .bt-grid {
  position: relative;
}

.datatable-check .bt-check .checkmark .checkmark__check {
  visibility: hidden;
  transition: all 0.3s;
}

.datatable-check .bt-check.checked .checkmark .checkmark__check {
  visibility: visible;
  stroke: #fff;
  -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.datatable-check .bt-check .checkmark .checkmark__circle {
  transition: all 0.3s;
  stroke: #ff5e15;
  fill: transparent;
  stroke-dashoffset: 0;
}

.datatable-check .bt-check.checked .checkmark .checkmark__circle {
  stroke: #ff5e15;
  fill: #ff5e15;
}

#template--toggler {
  width: 180px;
  flex: 0 0 180px;
}

.app-chat-write-message-templates .message .message-wrapper {
  width: 100% !important;
}

.datatable-check .bt-check .checkmark .checkmark__circle {
  transition: all 0.3s;
  stroke: #ff5e15;
  fill: transparent;
  stroke-dashoffset: 0;
}

.datatable-check .bt-check.checked .checkmark .checkmark__circle {
  stroke: #ff5e15;
  fill: #ff5e15;
}

.custom--listing {
  overflow-x: hidden;
}

.floating-action {
  position: absolute;
  opacity: 0;
  transform: translateX(100%);
  top: calc(50% - 14px);
  transition: all 0.3s;
  margin-left: 6px;
  right: 8px;
}

.custom--listing .bt-check:hover .floating-action {
  transform: translateX(0%);
  opacity: 1;
}

.floating-action .pointer-curser {
  display: block;
  max-width: 23px;
}

.dashboard-items-wrapper > .bt-row > .bt-column {
  flex: 0 0 25%;
  flex-wrap: nowrap;
}

app-dashboard-item img {
  width: 40px;
  height: auto;
}

.page-dashboard .page-padding {
  padding: 0px 50px;
}

app-dashboard-item h2 {
  font-size: 20px;
  margin-bottom: 6px;
  font-weight: 500;
}

stencil-route-link {
  display: block;
}

.menu-left bt-button {
  background-color: #fff;
}

.modal-supplier-database-holder .app-supplier-database.column-content {
  background-color: #fff;
}

.hero-banner .hb-bwrapper {
  height: 32.9vw;
  background-size: cover;
  background-position: center;
  position: relative;
  max-height: 570px;
}

.hb-bwrapper .filter-actions {
  margin-top: 0px;
}

.app-supplier-database .hb-bwrapper .filter-actions .bt-search {
  background-color: #fff;
}

.hb-bwrapper > .bt-grid {
  min-height: 100%;
}

.hb-bwrapper > .bt-grid > .bt-row {
  height: 100%;
  align-items: flex-end;
  padding: 20px;
}

.hb-bwrapper > .bt-grid > .bt-row > .bt-column {
  flex: 0 0 auto;
}

.app-supplier-database .hb-bwrapper .filter-actions .bt-search input {
  min-width: 150px;
  min-height: 48px;
}

.clm-right {
  margin-left: auto;
}

[data-counter] {
  position: relative;
}

[data-counter]:after {
  content: attr(data-counter);
  position: absolute;
  width: 20px;
  height: 20px;
  color: #fff;
  background-color: #fa3e3e;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
  font-size: 12px;
  border-radius: 10px;
  top: calc(50% - 10px);
  margin-left: 7px;
  transform: scale(0.9);
}

bt-accordion-body-content [data-counter]:after {
  right: -29px;
  line-height: 18px;
}

app-dashboard-item .notify {
  top: -9px;
  font-size: 11px;
  background-color: #fa3e3e;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  padding: 1px;
  text-align: center;
}

app-dashboard-item .notify span {
  line-height: 20px;
  top: auto !important;
  display: block;
}

.page--quotation .industry-items {
  flex-wrap: wrap;
}

app-dashboard-item p {
  max-width: calc(100% - 20px);
}

.section-strategy {
  padding-bottom: 258px;
}

.page-projects .menu-left .suppliers .sub-s-menu bt-button {
  padding-left: 52px;
}

.has-s-dropdown .dropdown-toggler {
  margin-left: 15px;
  margin-right: 0px !important;
}

.has-s-dropdown:hover .dropdown-toggler path {
  fill: #fff;
}

.dropdown-toggler {
  transition: all 0.2s;
}

.dropdown-toggler.is--open {
  transform: rotate(180deg);
}

.menu-left .bt-slider-item {
  min-width: 210px;
}

.menu-left .sub--project--single {
  min-width: 254px;
}

.page-projects .menu-left .chat-colleagues .chat-colleagues {
  margin-top: 0px;
  margin-bottom: 20px;
}

.upload-icon.contract-done img {
  background-color: green;
}

.project-loader {
  text-align: center;
}

.app-chat-mail-batch .suppliers-wrapper .error {
  margin-top: 0px;
  margin-bottom: 20px;
}

.style--message-chat {
  white-space: pre-wrap;
}

.error {
  text-align: left !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  color: var(--red-400);
}

.error.md-fl-error {
  position: relative;
  line-height: 0px;
  top: -20px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  text-align: left;
}

.page-projects-start {
  display: block;
  width: 100%;
}
.page-projects-start svg {
  margin-right: 10px;
  top: 1px;
  position: relative;
  transition: all 1s ease-in-out;
  animation: ani 1.2s infinite;
}
.page-projects-start p {
  margin-top: 104px;
}
.page-projects-start .bg {
  position: absolute;
  left: 0px;
  bottom: 0px;
}
.page-projects-start .bg img {
  width: 100%;
  max-width: 440px;
}
@media only screen and (max-device-width: 400px) and (orientation: portrait) {
  .page-projects-start .bg img {
    max-width: 600px !important;
  }
}
@keyframes ani {
  0% {
    left: 0;
  }
  50% {
    left: -10px;
  }
  100% {
    left: 0;
  }
}

.page-projects .menu-left .pr-start-nav .suppliers ul {
  box-shadow: none;
}

.reson--text {
  margin-top: 20px;
  background-color: #fff;
}

.reson--text.app-chat-mail-batch .message .message-wrapper {
  width: 100%;
}

.reson--text.app-chat-mail-batch .message .message-wrapper textarea {
  width: 100%;
  height: 180px;
}

.bt-align-right-force {
  float: right;
}

.bt-search input::-ms-clear {
  display: none;
}

.search--wrapper {
  position: relative;
}

.ac-clear-search {
  position: absolute;
  right: 13px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
}

.ac-clear-search svg {
  transform: rotate(45deg);
}

.ic-info-text {
  color: #9399a7;
  vertical-align: super;
  margin-left: 20px;
}

div.bt-row.app-chat-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
div.bt-row.app-chat-footer .deletedSupplierContactMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  margin: 32px auto;    
  height: 52px;
  
  background: var(--yellow-50);
  border-radius: 6px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: var(--yellow-800);
}
div.bt-row.app-chat-footer .deletedSupplierContactMessage svg {
  margin-right: 12px;
}
.float-chat {
  background-color: #fff;
}

.info--panel .page-internal-chat-member {
  max-height: calc(100vh - 115px);
  overflow: overlay;
}

ol.progtrckr li.progtrckr-doing-done:before {
  background-color: #224380;
}

ol.progtrckr li.progtrckr-done:before {
  background-color: #224380;
}

.menu-left .logo-wrapper {
  padding: 16.6px 40px;
  border-bottom: 1px solid #e2e2ea;
  height: 55px;
}

.menu-top {
  display: flex;
  justify-content: space-between;
  height: 55px;
}

.uploaded-files .item-overflow .item svg {
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  opacity: 0.3;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
  top: -1px;
  vertical-align: super;
}

.uploaded-files .item-overflow .item:hover svg {
  opacity: 1;
}

.menu-left .logo-wrapper {
  padding: 11px 40px;
  border-bottom: 1px solid #e2e2ea;
  height: 55px;
}

.page-projects .menu-left .project--filter > bt-accordion-head bt-button span {
  color: #001759;
}

.btn-disable {
  pointer-events: none;
}

.grid-demand .industry-items li.industry-item {
  padding-right: 0px;
  margin-right: 0px;
}

.grid-demand .industry-items li.industry-item label {
  display: flex;
  align-items: center;
}

.grid-demand .industry-items li.industry-item label .checkmark {
  position: relative;
  top: 0;
  left: 0;
  margin-top: 0px;
  margin-left: -5px;
  flex: 0 0 22px;
}

.grid-demand .industry-items li.industry-item label .name:after {
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  margin: 0 0px;
  position: relative;
  bottom: 0;
  content: "";
  display: block;
  width: calc(100%);
  transform: scaleX(0);
  height: 1px;
  background-color: #ff5e15;
}

.grid-demand .industry-items li.industry-item .checked label .name:after {
  transform: scaleX(1);
}

.grid-demand .industry-items li.industry-item label .name {
  max-width: calc(100% - 50px);
}

.grid-demand .industry-items li.industry-item label:after {
  display: none;
}

.only--quotation * {
  visibility: visible !important;
}

.app-project-overview .app-project-overview-item {
  height: 100%;
}

.app-project-overview .overview-item-wrapper .item {
  display: flex;
  flex-direction: column;
}

.page-contract .app-project-overview .overview-item-wrapper .item {
  display: block;
  flex-direction: unset;
}

.app-project-overview .app-project-overview-item > .bt-grid {
  height: auto;
  min-height: auto;
}

div app-label .mandatory {
  margin-left: 3px;
}

.ff-role-dropdown {
  font-weight: 400;
  background-color: #002185;
  font-size: 22px;
  margin-top: 10px;
  padding: 8px 18px;
  display: block;
  border: none;
  
  letter-spacing: 0.02em;
  color: #ffffff;
  width: 100%;
  padding-right: 70px;
  background-image: url(./assets/images/dropdown-icon.png);
  background-size: 15px;
  background-position: 98% 50%;
}

.frc-spacing {
  margin-top: 20px !important;
}

.frc-spacing bt-button {
  margin-top: 20px !important;
}

.page-browser-support.or-landscape-only {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.page-browser-support.or-landscape-only.show {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}

.page-browser-support.or-landscape-only img {
  max-width: 410px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
}

.page-browser-support.or-landscape-only h2.title {
  margin-top: 0px;
}

.page-browser-support.or-landscape-only .bt-column {
  justify-content: center;
}

.page-paddings {
  position: relative;
}

.bt-modal-close {
  content: "";
  width: 23px;
  height: 25px;
  display: block;
  position: absolute;
  background-size: contain;
  z-index: 9;
  background-repeat: no-repeat;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.bt-modal-close-custom {
  content: "";
  width: 23px;
  height: 25px;
  display: block;
  position: absolute;
  background-size: contain;
  z-index: 9;
  background-repeat: no-repeat;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.bt-modal-close svg {
  fill: #f15e21;
  stroke: #f15e21;
  stroke-width: 40px;
  width: 23px;
  height: 23px;
}

.page-login .first-column {
  background-color: #f2f3f5;
}

.app-select-industry-types .bt-modal-close {
  display: none;
}

.datatable-check .bt-check .checkmark {
  position: static;
  margin-top: 0px;
}

.body-wrapper {
  overflow-x: hidden;
}

.page-projects ol.progtrckr + div {
  /* overflow-x: hidden; */
  -webkit-overflow-scrolling: touch;
}

.page-internal-chat {
  height: 100%;
}

.style--overview-eye-icon {
  margin-left: 8px;
}

.sp-icca-ov {
  position: absolute;
  margin-left: 65px;
  max-width: 32px;
  top: calc(50% - 13px);
  cursor: pointer;
}

.sl-version-selector {
  position: relative;
}

.sl-version-selector .dropdown-toggler {
  margin-left: 10px;
}

.sl-version-selector a {
  margin-left: 8px;
}

.sl-version-selector .js-version-name {
  margin-right: 8px;
}

.ov-toggle-box {
  position: absolute;
  min-width: 170px;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #fff;
  z-index: 100;
  padding: 0px 0px;
  top: 100%;
  left: 0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  height: auto;
  overflow: hidden;
  max-height: 0px;
  pointer-events: none;
  transition: all 0.3s;
}

.toggler-menu-box {
  height: auto;
  overflow: hidden;
  max-height: 0px;
  pointer-events: none;
  transition: all 0.3s;
}

.toggler-menu-box.js-open {
  max-height: 670px;
  padding: 10px 0px;
  pointer-events: all;
}

.ov-toggle-box.js-open {
  max-height: 670px;
  padding: 10px 0px;
  pointer-events: all;
}

.ov-toggle-box li {
  padding: 8px 26px;
  line-height: 16px;
  cursor: pointer;
}

.ov-toggle-box li.ovt-selected {
  font-weight: 600;
  color: #fa6407;
  position: relative;
  min-width: 125px;
}

.ov-toggle-box li.ovt-selected:before {
  content: "";
  position: absolute;
  top: calc(50% - 5px);
  left: 10px;
  background-image: url(./assets/images/right.png);
  width: 10px;
  height: 10px;
  background-size: contain;
}

.ov-wrapper {
  position: relative;
}

.sl-sp .sp-ic {
  cursor: pointer;
}

.sl-sp .sp-ic-meeting {
  margin-right: 24px;
  height: 15px;
}

.sl-sp .sp-ic-callIcon {
  margin-right: 24px;
  height: 18px;
}

.sl-sp,
.ov-hover-call-notes {
  display: flex;
  align-items: center;
}

.sl-sp .ov-wrapper {
  display: flex;
  align-items: center;
}

.sp-ic-msg {
  position: relative;
  left: -18px;
  top: -8px;
  background-image: url(./assets/images/msg-inactive.png);
  display: none;
  width: 17px;
  height: 17px;
  background-size: contain;
  cursor: pointer;
}

.ov-hover-call-notes.js-has-called:hover .sp-ic-msg {
  display: block;
}

.sp-ic-msg-show {
  position: relative;
  left: -18px;
  top: -8px;
  display: block;
  width: 17px;
  height: 17px;
  background-size: contain;
  cursor: pointer;
  background-image: url(./assets/images/msg-active.png);
}
.sp-ic-msg:hover {
  background-image: url(./assets/images/msg-active.png);
}

bt-accordion.project--filter.js-popen-filter[open]:after {
  content: "-";
}

bt-accordion.project--filter.js-popen-filter:after {
  content: "+";
  position: absolute;
  top: 11px;
  right: 16px;
  font-size: 26px;
  color: #ff5e15;
  pointer-events: none;
}

bt-accordion.project--filter.js-popen-filter {
  position: relative;
}

.suppliers-wrapper.handle-overflow .user-avatar .info {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Media Screens */
@media (max-width: 1025px) {
  .body-wrapper app-dashboard-item p {
    max-width: calc(100% - 15px);
    font-size: 14px;
  }

  .body-wrapper app-dashboard-item h2 {
    font-size: 16px;
    margin-bottom: 6px;
  }

  .body-wrapper app-dashboard-item img {
    width: 32px;
    height: auto;
    margin-right: 14px;
  }

  .body-wrapper .page-dashboard {
    min-width: 100%;
  }

  .body-wrapper .page-dashboard .client-name {
    font-size: 44px;
  }

  .body-wrapper .page-projects {
    min-width: 100%;
  }

  .body-wrapper .page-projects .menu-top li a bt-button {
    max-height: 55px;
    padding: 18px 17px !important;
  }

  .bt-modal .app-create-project {
    max-width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;
  }

  .bt-modal .app-chat-mail-batch {
    max-width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;
  }

  .bt-modal .modal--lg--style {
    max-width: calc(100%);
    width: 100%;
  }

  .bt-modal .app-create-project .colum-left .content-left {
    padding: 50px 40px;
  }

  .bt-modal .app-create-project .colum-right .content-right {
    padding: 50px 40px;
  }

  .bt-modal .app-create-project .colum-right .content-right h2 {
    font-size: 48px;
  }

  .bt-modal .app-create-project .colum-right .content-right p {
    font-size: 14px;
  }

  .page-projects ol.progtrckr + div {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .bt-modal .grid-demand {
    width: 100%;
  }

  .bt-modal .app-chat-mail-batch .colum-left .content-left {
    padding: 50px 40px;
  }

  .bt-modal .app-chat-mail-batch .colum-right .content-right {
    padding: 50px 40px;
  }

  .bt-modal .bt-modal-body .flex-fit {
    width: 100%;
  }

  .bt-modal.custom-modal-width[name="modal-supplier-database"]
    .app-supplier-database.column-content {
    padding: 0px;
  }

  .bt-modal.custom-modal-width[name="modal-supplier-database"] .bt-modal-close {
    top: 80px;
  }

  .bt-modal.custom-modal-width[name="modal-supplier-database"] .bt-modal-body {
    max-width: calc(100% - 120px);
    margin-left: auto;
    margin-right: auto;
  }

  .style--overview-eye-icon {
    margin-left: 10px;
  }

  .app-project-overview.no-area-msg .overview-item-wrapper .item {
    flex: 0 0 100%;
  }

  .app-project-overview.no-area-msg .overview-item-wrapper .item img {
    max-width: 100%;
  }

  .hb-bwrapper > .bt-grid > .bt-row {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .hb-bwrapper > .bt-grid > .bt-row > .bt-column:first-child {
    margin-top: auto !important;
  }

  .hb-bwrapper > .bt-grid > .bt-row > .bt-column:last-child {
    margin-top: 20px;
  }

  .app-supplier-database .filter-actions {
    margin-top: 20px !important;
  }

  .general--page--content {
    min-width: 100%;
  }

  #chat-msg-cn-wrapper.chat-msg-cn-wrapper.info--style .info--panel {
    flex: 0 0 250px;
    max-width: 250px;
  }

  #chat-msg-cn-wrapper.chat-msg-cn-wrapper.info--style #uploads-gallery-wrapper.uploads-gallery-wrapper {
    right: 250px;
    z-index: 1;
    width: 250px;
  }

  .app-chat-file-uploads .no-uploads {
    white-space: normal !important;
  }

  .app-project-settings {
    max-width: 280px;
    padding: 20px;
  }

  .app-project-settings .employees .employee {
    display: flex !important;
  }
  .page-projects .menu-top ul:first-child,
  .page-projects .menu-top li:first-child {
    margin-left: 1px !important;
  }

  .general--page--content .menu-top ul:first-child,
  .general--page--content .menu-top li:first-child {
    margin-left: 1px !important;
  }

  .menu-top li a bt-button {
    padding: 18px 17px !important;
  }

  .page-projects-single-inbox {
    height: 100%;
  }

  .page-internal-chat #chat-msg-cn-wrapper.chat-msg-cn-wrapper {
    height: calc(100% - 60px);
  }

  .page-projects-single-inbox #chat-msg-cn-wrapper.chat-msg-cn-wrapper {
    height: calc(100% - 60px);
  }

  .page-internal-chat .info--panel .page-internal-chat-member {
    max-height: calc(100vh - 116px);
    min-height: auto;
  }
}

.page-projects
  .menu-left
  .project--filter[open]
  > bt-accordion-head
  bt-button
  span {
  color: #ff5e15;
}

.hide-element {
  display: none !important;
}

.page--quotation .menu-list button a:visited {
  color: #001659;
}

.general--page--content .menu-left ul bt-button a {
  color: inherit;
}

.general--page--content .menu-left ul .link-active bt-button button {
  color: #ff6722;
}

.project-text-style {
  max-width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-projects .menu-left ul.chat-colleagues bt-button {
  font-weight: 500;
}

.page-projects .menu-left .sub--project--single .project-name button {
  display: block;
  width: 100%;
}

.page-projects .menu-left .sub--project--single .project-name button > span {
  display: flex;
  align-items: center;
}

.page-projects .menu-left .sub--project--single .project-name span {
  display: block;
}

.page-projects
  .menu-left
  .sub--project--single
  .project-name
  .project-text-style {
  width: calc(100% - 25px);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  float: right;
  max-width: initial;
  display: block;
}

.sup-list {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.chat-colleagues bt-button button span {
  max-width: 100%;
  overflow: auto;
  text-overflow: ellipsis;
  overflow-x: hidden;
  display: block !important;
}

.chat-colleagues bt-button button {
  width: 100%;
}

.bt-modal {
  top: 0px;
  left: 0px;
}

.acsp-bid {
  width: 100%;
}


.gui-blocker.open {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
}

.js-toggle-dropdown {
  cursor: pointer;
}

.js-toggle-dropdown.is--open svg,
svg.js-toggle-dropdown.is--open {
  transform: rotate(180deg);
}
.style--margin-left {
  margin-left: 10px;
}

.style--margin-right {
  margin-right: 10px;
}

.bt-button.push--right {
  margin-left: auto;
}
/*salman Css*/
.rdw-editor-main {
  height: 45vh !important;
}
.ss-lft-box .rdw-editor-main {
  height: 360px !important;
}
.content-left.allow-expand.task-main-box {
  height: 690px;
  overflow: scroll;
}

.excel-icon--style:hover{
  cursor: pointer;
}

.item.style--mainForthstep-sa {
  width: 100% !important;
  flex: 100% !important;
}
.upload-pdf-scction-main .icon-box-sa {
   width: calc(100% - 340px) !important;
}
.uplod-icon-box {
    padding: 10px !important;
    max-width: 170px !important;
    width: auto;
    display: inline-block;
}
.uplod-icon-box .btn-attach {
  min-height: 43px;
}
.uplod-icon-box .uploaded-files .item a {
  position: relative !important;
  width: 140px !important;
}
.uplod-icon-box .uploaded-files .item {
  padding: 0 !important;
      width: 140px !important;
}
.uplod-icon-box .uploaded-files .item svg {
  position: absolute;
  right: -15px;
  top: 15px;
  z-index: 999;
}
.uplod-icon-box .description {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90px;
}

/* Ipad responsive Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and(orientation: portrait) {
  .app-create-project .rdw-editor-main {
    height: 35vh!important;
  }
}

hr.vertical {
  width: 0px;
  height: 100%;
}

.flex-center-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* BITCARD NEW DESIGN */
.bitcard-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bitcard-header .title {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bitcard-header label {
  white-space: nowrap;
  color: #171724;
  
  font-size: 1.5em;
  letter-spacing: 0.05em;
  max-width: fit-content;
  margin-right: 20px
}

.supplier > div {
  height: 30px;
  padding: 0;
  width: 100%;
}

.supplier-main {
  display: block;
  width: 100%;
}

.supplier-first-row,
.supplier-second-row {
  width: 100%;
  flex-wrap: wrap;
}

.supplier-name-section {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.supplier-name-section .supplier-name {
  display: inline-block;
  white-space: nowrap;
}

.supplier-version-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.version-section {
  position: relative;
}

.version-section .ov-toggle-box {
  margin-left: -70px;
  min-width: 100px;
}

.bitcard-anbud-link {
  color: #ff5e15;
  text-decoration: none;
}

.price-section {
  text-align: right;
}

.price-section p {
  display: inline-block;
}

.supplier-version-section .supplier-name {
  display: inline-block;
}

.supplier-icons-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bitcard-wizard-changer-select,
.bitcard-pricetype-changer-select {
  padding-right: 25px;
  border: none;
  background-image: url(./assets/svg/arrow_stroke_down.svg);
  background-position: 96% center;
  background-size: 11px;
  
  font-family: var(--primaryFont);
  font-weight: 500;
  min-width: 120px;
  max-width: 180px;
}

.bitcard-budget-pricetype-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}
.bitcard-budget-section {
  width: 100%;
}
.bitcard-budget-section span {
  display: inline-block;
  margin-right: 5px;
}

.bitcard-budget-section .bitcard-budget-box {
  padding-right: 5px;
  color: inherit;
  border-radius: 4px;
  background-color: var(--gray-50);
  height: 32px;
  padding: 0 10px;
  width: 100%;
}

.bitcard-budget-section .bitcard-budget-box::placeholder {
  color: var(--gray-300);
  
  font-weight: 500;
}

.bottm-excel-box {
  float: right;
  margin-top: 10px;
  position: relative;
}

.bottm-excel-box .dropdown-toggler {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.bottm-excel-box .excel-dropdown-bottom {
  display: inline-block;
  min-width: 200px;
  margin: 30px 0;
}

.wrapper-excel-dropdown-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.wrapper-excel-dropdown-top .dropdown-toggler {
  margin-left: 10px;
}

.wrapper-excel-dropdown-top .js-version-name {
  margin-left: 5px;
}

.wrapper-excel-dropdown-top .excel-dropdown-top {
  margin-top: 5px;
  min-width: 70px;
  text-align: center;
}

/* Page Quotation heading fix */
.header label small {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 13px;
  display: inline-block;
}

/* Areas Category Modal - Areas list */
.industry-items .industry-item-label {
  display: inline-block;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin: 25px 0 5px;
}

.industry-items .search-bar {
  display: block;
  width: 100%;
  position: relative;
}

.industry-items .search-bar svg {
  position: absolute;
  top: calc(50% - 9px);
  left: 10px;
  width: 18px;
  height: 18px;
}

.industry-items .search-bar input {
  background: transparent;
  min-width: 220px;
  border: 1px solid #fff;
  padding: 8px 0px 8px 35px;
  font-weight: 500;
  color: #fff;
}

.ttrow-plane {
	height: 30px;
}

.sp-btn-group svg {
  width:16px;
  height:16px;
  fill: #ffffff;
}
 .sup-db-title {
  cursor: pointer;
}
 .sup-db-title.sorted {
  color: #ff5e15;
}
/* for development */
.console {
  border: 1px solid red !important;
}

.forsen-logo {
  height: 55px !important;
  margin-left: -39px !important;
}
.custom-select-area div div select option  {
  text-transform: lowercase !important;
}
.custom-select-area div div span span.labelValue  {
  text-transform: lowercase !important;
}
.custom-select-area div div select option::first-letter  {
  text-transform: uppercase !important;
}

.readOnlyAccess {
  pointer-events: none;
}
svg.readOnlyAccess {
  opacity: 0.3 !important;
}
.readOnlyAccessHard {
  display: none;
}
.suppliers th {
  padding: 0 !important;
}
body .MuiTableCell-root {
  font-family: var(--primaryFont);
}
app-label {
  text-transform: none;
  font-size: unset;
}

@media (max-width:1366px){
  .bt-column.bt-vcenter.right.bt-contract .bt-select-wrapper .custom-select-area .bt-select {
  max-width: 177px !important;
  }
  
  .bt-column.bt-vcenter.right.bt-contract .bt-select-wrapper .custom-select-area .bt-select .values {
  max-width: 145px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  }
  }
.file-dropper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  background: #FFFFFF;
  border: 1px dashed #D1D5DB;
  box-sizing: border-box;
  border-radius: 4px;
  text-transform: none;
}

label.ar-label .file-dropper-container span {
  text-transform: none;
  align-self: flex-start;
  font-weight: normal;
  margin-bottom: 8px;
}

.file-dropper-container .hairLine {
  border-bottom: 1px solid #e2e2ea;
  margin: 6px 0px;
  width: 100%;
}

.file-drop-target {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: var(--gray-700);
}
p.file-tips {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: var(--gray-500);
  margin: 0;
}
.color-gray-700{
  color: var(--gray-700);
}
.color-gray-200{
  color: var(--gray-200);
  opacity: 0.5;
}
.color-gray-100{
  color: var(--gray-100);
}
.generic-card {
  display: block;
  background-color: #fff;
  padding: 29px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--gray-300);
}
.fullHeightView {
  min-height: 100vh
}

.MuiRadio-root:hover {
  background-color: var(--gray-50) !important;
}